import React from "react";

class CustomError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      msg: "",
    };
  }

  renderHubungiPusatBantuanButton() {
    const color = "#2f3031";
    const backgroundColor = "#ffffff";
    const borderWidth = "1px";
    const text = "Hubungi Pusat Bantuan";
    const customAStyle = {display: "flex", color: "#000", justifyContent: "center", alignItems: "center", alignSelf: "stretch"};
    const customButtonStyle = {padding: "12px 20px", gap: "4px", backgroundColor: backgroundColor, borderWidth: borderWidth, borderColor: "#808489", borderRadius: "4px", cursor: "pointer", color: color, fontWeight: 600};

    return (
      <a href="https://pusatinformasi.siplah.kemdikbud.go.id/hc/en-gb/requests/new" style={customAStyle}>
        <button style={customButtonStyle}>
          {text}
        </button>
      </a>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="error-content" style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "32px"}}>
          <div className="image-container" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="120" height="120" rx="60" fill="#EAEAEA" />
              <path d="M101.904 92.6335C101.904 93.4192 101.265 94.0582 100.48 94.0582H93.8691C95.6187 94.0582 97.0749 92.8535 97.4206 91.1982H100.48C101.265 91.1982 101.904 91.8373 101.904 92.623V92.6335Z" fill="#EAEAEA" />
              <path d="M97.7662 71.3562V90.4124C97.7662 90.5067 97.7662 90.6114 97.7557 90.6952C97.7452 90.7895 97.7243 90.8943 97.7138 90.9781C97.4519 92.7381 95.9433 94.0476 94.1205 94.0476H86.5566C88.5681 94.0476 90.2024 92.4238 90.2024 90.4019V83.0057C90.2024 83.0057 95.7443 76.3638 97.7662 71.3457V71.3562Z" fill="#020A11" />
              <path d="M86.0748 52.1953C86.0748 52.1953 82.7015 73.221 74.5091 79.3496C69.0196 83.4458 50.3196 89.3334 50.3196 89.3334C50.3196 89.3334 46.4853 90.2553 44.7777 92.0886C44.5158 91.5963 44.3691 91.0096 44.3691 90.4229V64.1486C44.3691 64.1486 48.8634 52.2058 56.4482 52.2058H86.0748V52.1953Z" fill="#F5CD2B" />
              <path d="M90.2024 55.8413V64.6413C90.2024 64.6413 84.6919 84.2422 81.2767 86.327C77.4005 88.6946 58.8471 93.3984 58.8471 93.3984C58.8471 93.3984 56.9929 93.9013 56.9929 94.0689H48.0252C47.5224 94.0689 47.03 93.9641 46.5795 93.7651C45.8043 93.4299 45.1757 92.8432 44.7881 92.0994C46.5062 90.2661 50.33 89.3441 50.33 89.3441C50.33 89.3441 69.03 83.4565 74.5195 79.3603C82.7119 73.2318 86.0852 52.2061 86.0852 52.2061H86.5671C88.5786 52.2061 90.2128 53.8403 90.2128 55.8518L90.2024 55.8413Z" fill="#EFB003" />
              <path d="M97.7665 55.8411V56.6268C92.717 62.7449 90.2027 64.6306 90.2027 64.6306V55.8306C90.2027 53.903 88.6836 52.3106 86.777 52.1954H94.1208C96.1427 52.1849 97.7665 53.8192 97.7665 55.8306V55.8411ZM81.277 86.3163C77.4008 88.6839 58.8475 93.3878 58.8475 93.3878C58.8475 93.3878 56.9932 93.8906 56.9932 94.0582H75.976C76.7303 93.9011 79.8208 92.2458 83.2465 91.1982C88.2122 89.6897 90.2027 83.0058 90.2027 83.0058V64.6306C90.2027 64.6306 84.6922 84.2316 81.277 86.3163Z" fill="#D87E00" />
              <path d="M97.7661 56.6372V71.3563C95.7442 76.3743 90.2023 83.0163 90.2023 83.0163V64.641C90.2023 64.641 92.7165 62.7553 97.7661 56.6372ZM83.2356 91.2191C79.8099 92.2563 76.7299 93.922 75.9756 94.0686H86.5461C88.5575 94.0686 90.1918 92.4448 90.1918 90.4229V83.0267C90.1918 83.0267 88.1908 89.721 83.2251 91.2191H83.2356Z" fill="#7C3B00" />
              <path d="M73.0945 69.7536C73.0945 67.6688 71.4498 65.9717 69.4174 65.9717C67.385 65.9717 65.7402 67.6688 65.7402 69.7536C65.7402 70.8641 66.2221 71.8593 66.9659 72.5507V79.2136C66.9659 79.8945 67.5002 80.4498 68.1707 80.4498H70.664C71.324 80.4498 71.8688 79.8945 71.8688 79.2136V72.5507C72.6126 71.8593 73.0945 70.8641 73.0945 69.7536Z" fill="#7C3B00" />
              <path d="M56.4589 52.2061C48.8637 52.2061 44.3799 64.1489 44.3799 64.1489V55.8413C44.3799 53.8299 46.0142 52.2061 48.0256 52.2061H56.4589Z" fill="#FFEF27" />
              <path d="M86.7766 52.2058C86.7033 52.1953 86.63 52.1953 86.5566 52.1953H86.7766V52.2058Z" fill="#7C3B00" />
              <path d="M54.9816 65.9822H54.9083C54.7197 65.9822 54.5312 66.066 54.374 66.2336C54.5416 66.066 54.7093 65.9717 54.9083 65.9717C54.9293 65.9717 54.9607 65.9717 54.9816 65.9822Z" fill="#071A8C" />
              <path d="M83.0888 64.6409C86.0222 64.6409 88.4002 62.2629 88.4002 59.3295C88.4002 56.3961 86.0222 54.0181 83.0888 54.0181C80.1554 54.0181 77.7773 56.3961 77.7773 59.3295C77.7773 62.2629 80.1554 64.6409 83.0888 64.6409Z" fill="#CE0000" />
              <path d="M83.8642 59.3297L85.6975 57.4963C85.907 57.2868 85.907 56.9306 85.6975 56.7211C85.488 56.5116 85.1318 56.5116 84.9223 56.7211L83.089 58.5544L81.2556 56.7211C81.0461 56.5116 80.6899 56.5116 80.4804 56.7211C80.2709 56.9306 80.2709 57.2868 80.4804 57.4963L82.3137 59.3297L80.4804 61.163C80.2709 61.3725 80.2709 61.7287 80.4804 61.9383C80.5851 62.043 80.7318 62.0954 80.868 62.0954C81.0042 62.0954 81.1509 62.043 81.2556 61.9383L83.089 60.1049L84.9223 61.9383C85.0271 62.043 85.1737 62.0954 85.3099 62.0954C85.4461 62.0954 85.5928 62.043 85.6975 61.9383C85.907 61.7287 85.907 61.3725 85.6975 61.163L83.8642 59.3297Z" fill="white" />
              <path d="M72.4143 69.7115C72.4143 70.7801 71.9743 71.7229 71.2515 72.3829V75.8296C71.2515 75.8296 69.1772 78.7105 66.5791 78.8677C66.5791 78.8258 66.5791 78.7839 66.5791 78.742V76.2277C70.1724 75.3267 70.581 72.3305 70.581 72.3305C70.581 72.3305 71.6601 70.9058 71.6601 69.2086C71.6601 67.6163 69.3762 66.2648 69.1248 66.1182C70.9686 66.2229 72.4248 67.7944 72.4248 69.7115H72.4143Z" fill="#0E43C1" />
              <path d="M54.3844 63.8448C54.1644 63.4676 53.8815 63.2476 53.5987 63.2476H53.5777L53.3263 63.3105L43.6777 65.741L40.5244 66.5372C37.9053 62.0953 32.353 59.8743 27.1358 61.8333C22.3901 63.6143 19.4358 68.5905 20.1168 73.6086C21.0387 80.2191 27.4292 84.3991 33.7253 82.8067C38.5863 81.5914 41.8549 77.3276 42.033 72.5714L43.7406 72.1419L48.3187 70.9895L53.5253 69.68C53.5253 69.68 53.6091 69.6486 53.6511 69.6276C54.3739 69.5648 54.9396 68.1714 54.9396 66.4429C54.9396 65.3743 54.7196 64.421 54.3739 63.8448H54.3844ZM37.9472 73.6086C37.413 76.1648 35.4749 78.3124 32.772 78.9933C28.9272 79.9676 25.0301 77.5895 24.1396 73.7238C23.2596 69.8895 25.8472 65.94 29.7025 65.1962C32.2796 64.6933 34.8358 65.6781 36.4282 67.5743C37.1092 68.3705 37.6225 69.3343 37.8949 70.4238C38.1672 71.4924 38.1672 72.5924 37.9472 73.6086Z" fill="#458CFF" />
              <path d="M27.1254 61.8439C22.5787 63.5515 19.6663 68.203 20.0435 73.0115C20.473 67.6058 24.5063 62.7658 30.0378 61.6972C31.4521 61.4249 32.8663 61.4039 34.2178 61.603C31.9968 60.922 29.514 60.9325 27.1149 61.8439H27.1254Z" fill="#6BBDF7" />
              <path d="M57.7475 69.2089C57.7475 70.927 57.1608 72.3203 56.4485 72.3203C55.7361 72.3203 55.1494 70.927 55.1494 69.2089C55.1494 67.4908 55.7361 66.1079 56.4485 66.1079C57.1608 66.1079 57.7475 67.4908 57.7475 69.2089ZM66.5894 78.8679C66.6523 79.4546 67.1447 79.9155 67.7313 79.9155H70.1094C70.7485 79.9155 71.2513 79.3812 71.2513 78.7422V75.8298C71.2513 75.8298 69.177 78.7108 66.5789 78.8679H66.5894Z" fill="#071A8C" />
              <path d="M71.6496 69.2089C71.6496 70.906 70.5705 72.3308 70.5705 72.3308C70.5705 72.3308 70.1619 75.327 66.5686 76.2279V72.3727L66.5267 72.3308H56.4381C56.2496 72.3308 56.0819 72.2365 55.9248 72.0793C56.0819 72.2365 56.26 72.3203 56.4381 72.3203C57.161 72.3203 57.7372 70.927 57.7372 69.2089C57.7372 67.4908 57.2029 66.1917 56.5115 66.1079H68.9153C68.9781 66.1079 69.0515 66.1079 69.1143 66.1079C69.3658 66.2546 71.6496 67.606 71.6496 69.1984V69.2089Z" fill="#2560E0" />
              <path d="M56.3746 60.1364L54.9394 55.7679C54.7613 55.2126 54.1642 54.9193 53.6194 55.0974C53.0746 55.286 52.7708 55.8726 52.9594 56.4174L54.3946 60.786C54.5727 61.3412 55.1699 61.6345 55.7146 61.4564C56.2594 61.2679 56.5632 60.6812 56.3746 60.1364Z" fill="#2560E0" />
              <path d="M60.4397 61.247L62.514 57.1404C62.7759 56.627 62.5664 55.9985 62.053 55.7366C61.5397 55.4746 60.9111 55.6842 60.6492 56.1975L58.5749 60.3042C58.313 60.8175 58.5226 61.4461 59.0359 61.708C59.2978 61.8442 59.5806 61.8546 59.8425 61.7708C60.1045 61.687 60.314 61.5089 60.4502 61.247H60.4397Z" fill="#2560E0" />
              <path d="M67.5735 62.1687C67.3954 61.6135 66.7982 61.3202 66.2535 61.4983L61.8849 62.9335C61.3401 63.1221 61.0468 63.7087 61.2249 64.2535C61.403 64.8087 62.0001 65.1021 62.5449 64.924L66.9135 63.4887C67.4582 63.3002 67.7516 62.7135 67.5735 62.1687Z" fill="#2560E0" />
              <path d="M54.3843 63.8452C54.1643 63.468 53.8815 63.248 53.5986 63.248H53.5776L53.3262 63.3109L45.731 65.2176C46.2967 69.7642 45.1653 70.6338 43.751 72.1319L48.3291 70.9795L53.5357 69.67C53.5357 69.67 53.6195 69.6385 53.6615 69.6176C54.3843 69.5547 54.95 68.1614 54.95 66.4328C54.95 65.3642 54.73 64.4109 54.3843 63.8347V63.8452Z" fill="#2560E0" />
              <path d="M41.7499 74.625C40.8594 78.5117 37.8737 81.7802 33.7251 82.8174C30.9804 83.5193 28.2251 83.1107 25.8994 81.885C28.1623 82.7231 30.687 82.9117 33.2013 82.2726C37.308 81.2355 40.3985 78.2917 41.7499 74.625Z" fill="#2560E0" />
              <path d="M85.2365 27.8382C81.8527 23.7315 75.2841 22.1077 69.187 22.8306C59.8318 23.5429 54.468 31.0334 55.1908 39.1839V50.1525C55.1908 50.1525 58.1032 50.3096 61.4765 49.1468V39.0687C61.4765 38.6915 61.288 35.2763 63.666 32.4687C66.526 28.6344 77.3375 28.6239 80.2394 32.4268C82.5337 34.9829 82.4603 38.7125 82.4603 38.7229V44.4534C84.9746 42.641 87.1746 40.3363 88.7775 37.4029C88.7041 35.0668 88.1175 31.1068 85.268 27.8487L85.2365 27.8382Z" fill="#CECECE" />
              <path d="M61.0992 26.7173C60.8582 26.8639 55.5468 30.2268 56.0392 39.2887V41.6249C56.0392 42.1278 55.6411 42.5363 55.1592 42.5363V39.163C55.1592 39.0792 55.1382 38.9744 55.1382 38.8382C55.1382 38.8173 55.1382 38.7963 55.1382 38.7754C55.1382 38.482 55.1173 38.0525 55.1382 37.5182C55.1906 35.1925 55.7249 31.1487 58.6582 27.8173C59.4649 26.9059 60.3763 26.0992 61.403 25.4287C61.403 25.4497 61.4344 25.4601 61.4344 25.4811C61.6858 25.9106 61.5392 26.4554 61.1201 26.7173H61.0992ZM55.1592 43.8773V46.0878C55.6306 46.0668 56.0077 45.6792 56.0077 45.1763V44.7782C56.0077 44.2859 55.6306 43.8878 55.1592 43.8668V43.8773Z" fill="#EAEAEA" />
              <path d="M88.7147 44.7365V52.206H82.4185V48.4556H82.429C85.5718 47.5232 87.9185 46.2975 88.6833 44.7051L88.7042 44.747L88.7147 44.7365ZM55.1804 51.5565L55.2537 52.206H61.4556V51.3365C57.7366 51.5356 55.1699 51.5565 55.1699 51.5565H55.1804Z" fill="#9A9B9B" />
              <path d="M61.4556 51.3256C57.7366 51.5247 55.1699 51.5456 55.1699 51.5456V50.1418C55.1699 50.1418 58.0823 50.299 61.4556 49.1361V51.3361V51.3256ZM88.7356 37.3818C87.1328 40.3152 84.9328 42.6199 82.4185 44.4323C82.4185 45.7523 82.408 47.1456 82.4185 48.4447C85.5614 47.5123 87.908 46.2866 88.6728 44.6942L88.6937 44.7361V39.1837C88.7147 38.8695 88.7461 38.2304 88.7252 37.3923L88.7356 37.3818Z" fill="#A7A8A8" />
            </svg>
          </div>
          <span style={{color: "var(--Text-Default, #2F3031)", textAlign: "center", fontFamily: "Inter", fontSize: "24px", fontStyle: "normal", fontWeight: 600, lineHeight: "28px"}}>
            Gagal Masuk ke Halaman Mitra SIPLah
          </span>
          <span style={{color: "var(--Text-Subdued, #65686C)", textAlign: "center", fontFamily: "Inter", fontSize: "20px", fontStyle: "normal", fontWeight: 400, lineHeight: "28px", width: "600px"}}>
            Maaf, terjadi gangguan pada sistem Dapodik. Silakan hubungi Pusat Bantuan SIPLah untuk mendapat bantuan lebih lanjut.
          </span>
          {this.renderHubungiPusatBantuanButton()}
        </div>
      </React.Fragment>
    );
  }
}

export default CustomError;
