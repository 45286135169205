// Copyright 2021 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import {createButton} from "react-social-login-buttons";

class SelfLoginSectionCustom extends React.Component {
  generateIcon() {
    const avatar = this.props.account.avatar;
    return () => {
      return (
        <div>
          <img width={36} height={36} src={avatar} alt="-" />
        </div>
      );
    };
  }

  generateNoIcon() {
    return () => {
      return (
        <div>
        </div>
      );
    };
  }

  getAccountShowName() {
    const {email} = this.props.account;
    return email;
  }

  render() {
    const config = {
      icon: this.generateNoIcon(),
      iconFormat: name => `fa fa-${name}`,
      iconSize: "0px",
      style: {background: "#0b5fef", color: "#ffffff", width: "100%", height: "100%", padding: "12px 20px", margin: 0, fontWeight: 600, fontSize: "16px", lineHeight: "24px", borderRadius: "4px", boxShadow: "none"},
      activeStyle: {background: "#103178"},
    };

    const SelfLoginSectionCustom = createButton(config);
    return (
      <div style={{marginTop: "4px"}}>
        <div style={{border: "1px solid #dadce0", marginBottom: "12px", alignItems: "center", display: "flex", borderRadius: "4px", padding: "12px"}}>
          {
            this.generateIcon()()
          }
          <div style={{marginLeft: "12px", fontSize: "16px", lineHeight: "24px"}}>
            {
              this.getAccountShowName()
            }
          </div>
        </div>
        <SelfLoginSectionCustom text="Selanjutnya" onClick={this.props.onClick} align={"center"} />
      </div>
    );
  }
}

export default SelfLoginSectionCustom;
